import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import {
  courierLogAbsensiUseCase
} from '@/domain/usecase'
import moment from 'moment'
import { useStore } from 'vuex'

export default {
  name: 'CourierAttendanceHistory',
  props: {
    Id: {
      default: () => null
    }
  },
  setup(props: any) {
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd,
      $numbers
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const data = ref([])
    const totalRecords = ref(0)
    const filters = {
      offset: 0,
      top: $numbers.totalItemPerPage,
      skip: 0,
      sorting: 'ClockIn desc',
      custom: `KurirMsUserId eq ${props.Id}`,
      expand: '&$expand=MsAgenIdInNavigation($select=Id,Nama),MsAgenIdOutNavigation($select=Id,Nama)'
    }

    const getDataLogAbsensies = () => {
      store.dispatch('showLoading')
      courierLogAbsensiUseCase.getAll(filters).then((response: any) => {
        if (!response.error) {
          totalRecords.value = response.count
          data.value = response.result
        } else {
          $toast.add({
            severity: 'error',
            detail: $strInd.toast.errorAllData,
            group: 'bc',
            life: 3000
          })
        }
        store.dispatch('hideLoading')
      })
    }

    const onPage = (event: any) => {
      filters.skip = event.page * filters.top
      getDataLogAbsensies()
    }

    onMounted(() => {
      getDataLogAbsensies()
    })

    return {
      data,
      onPage,
      filters,
      moment,
      totalRecords
    }
  }
}
